import React from "react"
import { Container, Row, Col } from 'react-bootstrap'
import { injectIntl, Link } from "gatsby-plugin-react-intl"

const HeroSubpage = ({ intl, theme, content }) => (
    <div className={`hero-subpage ` + theme}>
        <div className={`clipped-area`}></div>
        <div className={`non-clipped-area`}>
            <Container>
                <Row>
                    <Col md={8}>
                        {content.breadcrumbs &&
                        <div className={`back`}>
                            {content.breadcrumbs.map((link, i) => {
                                return(
                                    <span key={i}>
                                        <Link className={`link`} to={link.url}>
                                            {link.label}
                                        </Link>
                                        {' / '}
                                    </span>
                                )
                            })}
                        </div>
                        }
                        <h1>{content.title}</h1>
                        <div className={`text`} dangerouslySetInnerHTML={{ __html: content.text }}></div>
                    </Col>
                    <Col md={4}>

                    </Col>
                </Row>
            </Container>
        </div>
    </div>
)

export default injectIntl(HeroSubpage)
