import React from "react"
import '@formatjs/intl-locale/polyfill'
import '@formatjs/intl-getcanonicallocales/polyfill'
import { injectIntl } from "gatsby-plugin-react-intl"
import Layout from "../../components/layout"
import Seo from "../../components/seo"
import ClientsTicker from "../../components/misc/clients-ticker";
import HeroData from "../../content/unsubscribe/error.json"
import TickerData from "../../content/home/ticker.json"
import SimpleMailingList from "../../components/ctas/simple-mailing-list"
import HeroSubpage from "../../components/heroes/hero-subpage"

const UnsubscribeErrorPage = ({ intl }) => (
    <Layout>
        <Seo lang={intl.locale}
             title={intl.formatMessage({ id: "pages.unsubscribe.error.title" })}
             description={intl.formatMessage({ id: "pages.unsubscribe.error.description" })}
        />
        <HeroSubpage
            theme={`dark`}
            content={HeroData[intl.locale]}
        />
        <ClientsTicker content={TickerData[intl.locale]} />
        <SimpleMailingList />
    </Layout>
)

export default injectIntl(UnsubscribeErrorPage)
